<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="30.000000pt" height="30.000000pt" viewBox="0 0 900.000000 900.000000"
       preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
      <path d="M0 4500 l0 -4500 4500 0 4500 0 0 4500 0 4500 -4500 0 -4500 0 0
-4500z m4483 1524 c154 -34 313 -139 400 -266 35 -50 88 -162 192 -403 24 -55
87 -199 140 -320 53 -121 110 -249 125 -285 29 -69 94 -217 290 -665 67 -154
144 -329 170 -390 27 -60 81 -184 120 -275 101 -232 170 -389 186 -427 l14
-33 -697 0 -698 0 -42 93 c-23 50 -56 124 -74 162 -17 39 -57 126 -89 195
-108 239 -125 275 -156 340 l-32 65 -74 -155 c-42 -85 -103 -212 -136 -282
-53 -110 -71 -136 -134 -200 -41 -40 -99 -88 -130 -106 -359 -212 -814 -37
-945 363 -22 66 -26 99 -26 190 0 120 16 184 84 331 31 68 81 178 271 599 52
116 120 264 150 330 30 66 73 161 96 210 284 632 287 637 382 738 67 71 95 92
172 131 147 74 290 93 441 60z"/>
    </g>
  </svg>
</template>
<script setup lang="ts">
</script>
